<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BorderTitleSelect
      :options="store.state.general.commonAreas"
      label="Área Común"
      v-model="reserve.selectedCommonArea"
      @change="onChangeCommonArea"
    />
    <BasicFullCalendar
      :events="store.state.reserve.availableDays"
      :changeMonth="onChange"
      :eventClick="onClick"
    />
    <BasicModal v-model:visible="reserve.showDialog">
      <template v-slot:dialog>
        <BasicTitle :title="$route.name" />
        <BasicSubtitle
          subtitle="Los campos señalados con (*) son obligatorios."
        />
        <div class="container-form">
          <BasicLabel label="Fecha *" />
          <BorderDate
            v-model="reserve.data.date"
            :disabled="true"
            label="Fecha *"
          />
          <FormError
            :show="reserve.rules.date"
            message="Seleccione una fecha"
          />
          <BasicLabel label="Turno *" />
          <BorderSelect
            v-model="reserve.data.turn"
            label="Turno *"
            :options="reserve.turns"
          />
          <FormError :show="reserve.rules.turn" message="Seleccione un Turno" />
          <BasicLabel label="Apartamento *" />
          <BorderSelect
            v-model="reserve.data.apartment"
            label="Apartamento *"
            :options="reserve.apartments"
          />
          <FormError
            :show="reserve.rules.apartment"
            message="Seleccione un apartamento"
          />
          <BasicLabel label="Costo" />
          <BorderInput
            v-model="reserve.data.cost"
            label="Costo"
            :disabled="true"
          />
          <FormError :show="reserve.rules.cost" />
          <BasicLabel label="Aforo Máximo" />
          <BorderInput
            v-model="reserve.data.aforo"
            label="Aforo Máximo"
            :disabled="true"
          />
          <FormError />
          <BasicLabel label="Comentarios" />
          <OutlinedTextArea v-model="reserve.data.comment" />
          <FormError :show="reserve.rules.comment" />
          <PrimaryButton label="Reservar" :click="onSave" />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import BasicFullCalendar from "../../widgets/calendar/BasicFullCalendar.vue";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, actions, currency } from "../../constants";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import BorderTitleSelect from "../../widgets/select/BorderTitleSelect.vue";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";

export default {
  components: {
    BasicFullCalendar,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
    BasicModal,
    BorderTitleSelect,
    BorderDate,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const reserve = reactive({
      selectedCommonArea: "",
      showDialog: false,
      turns: [],
      apartments: [],
      data: {
        id: "",
        date: "",
        turn: "",
        apartment: "",
        cost: "",
        comment: "",
        limit: "",
        aforo: "",
      },
      rules: {
        date: false,
        turn: false,
        apartment: false,
      },
      selectedDate: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
    });

    const validate = () => {
      let valid = true;

      if (!reserve.data.date) {
        reserve.rules.date = true;
        valid = false;
      }
      if (!reserve.data.turn) {
        reserve.rules.turn = true;
        valid = false;
      }
      if (!reserve.data.apartment) {
        reserve.rules.apartment = true;
        valid = false;
      }

      return valid;
    };

    const onChange = async (month, year) => {
      reserve.selectedDate.month = month;
      reserve.selectedDate.year = year;
      await onChangeCommonArea();
    };
    const onClick = async (info) => {
      reserve.data.date = new Date(info.event.start);
      store.commit("setLoading", true);
      const response = await store.dispatch(actions.reserveActions.turns, {
        commonAreaId: reserve.selectedCommonArea,
        date: new Date(reserve.data.date).getTime(),
      });
      if (response.ok) {
        reserve.turns = response.turns;
        reserve.apartments = response.apartments;
        reserve.data.cost = currency.convertNumberToCurrency(response.cost);
        reserve.data.aforo = response.aforo;
        /* reserve.data.limit = reserve.turns.length
          ? `${reserve.turns[0].limit} reservas disponibles`
          : ""; */
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
      store.commit("setLoading", false);
      if (response.turns.length) {
        reserve.showDialog = true;
      }
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: reserve.data.id,
        apartmentId: reserve.data.apartment,
        turnId: reserve.data.turn,
        reserveDate: new Date(reserve.data.date).getTime(),
        comment: reserve.data.comment,
      };

      let response = null;
      if (store.state.openMode === openMode.CREATE) {
        response = await store.dispatch(actions.reserveActions.create, request);
      }
      store.commit("setLoading", false);
      if (response.ok) {
        await store.commit("setSelectedReserves", []);
        await store.commit("setAvailableDays", []);
        router.push("/reservas/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    const onChangeCommonArea = async () => {
      if (reserve.selectedCommonArea) {
        const date = new Date();
        date.setMonth(reserve.selectedDate.month);
        date.setFullYear(reserve.selectedDate.year);
        store.commit("setLoading", true);
        await store.dispatch(actions.reserveActions.days, {
          commonAreaId: reserve.selectedCommonArea,
          date: new Date(date).getTime(),
        });
        store.commit("setLoading", false);
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/reservas/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch(
        actions.generalActions.commonAreas,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("setAvailableDays", []);
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (reserve.data.date) reserve.rules.date = false;
      if (reserve.data.turn) reserve.rules.turn = false;
      if (reserve.data.apartment) reserve.rules.apartment = false;
    });

    return {
      store,
      reserve,
      onSave,
      onChange,
      onClick,
      onChangeCommonArea,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
