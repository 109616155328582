<template>
  <FullCalendar :events="events" :options="options" class="calendar" />
</template>

<script>
import FullCalendar from "primevue/fullcalendar";
import "@fullcalendar/core";
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: { FullCalendar },
  props: {
    events: {
      type: Array,
      default: [],
    },
    dateClick: {
      type: Function,
      default: (e) => {
        console.log(e);
      },
    },
    changeMonth: {
      type: Function,
      default: (month, year) => {
        console.log(month, year);
      },
    },
    eventClick: {
      type: Function,
      default: (info) => {
        console.log(info);
      },
    },
    // disabledDays: {
    //   type: Array,
    //   default: [],
    // },
  },
  setup(props) {
    // const onDisabledDays = (ev) => {
    //   props.disabledDays.forEach((item) => {
    //     if (new Date(ev.date).getDate() === new Date(item).getDate()) {
    //       ev.el.setAttribute("style", "opacity: 0.3;");
    //     }
    //   });
    // };

    // const onDateClick = (ev) => {
    //   if (new Date(ev.date).getMonth() !== selectedMonth) return;
    //   const finded = props.disabledDays.find(
    //     (item) =>
    //       new Date(ev.date).getDate() === new Date(item).getDate() &&
    //       new Date(ev.date).getMonth() === new Date(item).getMonth() &&
    //       new Date(ev.date).getFullYear() === new Date(item).getFullYear()
    //   );
    //   if (finded) return;
    //   props.dateClick(ev);
    // };

    let selectedMonth = new Date().getMonth();
    let selectedYear = new Date().getFullYear();

    const options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialDate: new Date(),
      headerToolbar: {
        left: "title",
        center: "",
        right: "prev,next",
        // right: 'dayGridMonth,timeGridDay',
      },
      locale: esLocale,
      // editable: true,
      dateClick: props.dateClick,
      eventClick: props.eventClick,
      // dayCellDidMount: onDisabledDays,
      datesSet: (ev) => {
        if (new Date(ev.start).getDate() > 1) {
          if (new Date(ev.start).getMonth() < 11) {
            selectedMonth = new Date(ev.start).getMonth() + 1;
            selectedYear = new Date(ev.start).getFullYear();
          } else {
            selectedMonth = 0;
            selectedYear = new Date(ev.start).getFullYear() + 1;
          }
        } else {
          selectedMonth = new Date(ev.start).getMonth();
          selectedYear = new Date(ev.start).getFullYear();
        }
        props.changeMonth(selectedMonth, selectedYear);
      },
    };

    return { options };
  },
};
</script>

<style scoped>
.calendar {
  margin: 2rem 0;
  width: 100%;
  max-width: var(--lg);
  padding: 0 2rem;
}
</style>
